import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Table} from "reactstrap"
import Nebo from "../components/nebo"
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"

const AboutPage = () => (
  <Layout>
    <SEO
      title="About Spark Files"
      description="Spark Files explores word, phrase and cultural customs origins. This is the page where you can learn more about us."
      image="https://sparkfiles.net/og-image.jpg"
      url="https://sparkfiles.net/about/"
    />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          <PurpleSection>
            <AboutContent>
              <h1 className="text-center" style={{fontFamily: fonts.mainFont}}>
                Welcome to <span style={{color: colors.meatRare}}>Spark</span>
                <span style={{color: colors.meatFire}}>Files</span>
              </h1>
            </AboutContent>
          </PurpleSection>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <p>
              <b>Hi.</b>
            </p>
            <p>
              <i>
                Are you passionate about the origin of words (etymology),
                phrases and customs?
              </i>
            </p>
            <p>
              For many years I have been interested in the origins of common,
              everyday words and phrases and as a result I started this blog to
              document the information I research.
            </p>
            <p>
              Most people don’t stop to think about where the common words and
              phrases they use come from but I believe that this site will at
              least get people thinking.
            </p>
            <p>
              Much in the same way that people research their family’s ancestry
              this blog attempts to delve into the history of words, phrases and
              customs.
            </p>
            <p>
              As a research scientist by profession I do my best to dig up the
              most relevant and accurate information on any particular subject I
              am looking into.
            </p>
            <p>
              Some common words and phrases won’t appear on this blog because:
              1) I haven’t added them yet or 2) after researching it the origin
              appears to have no clear explanation.
            </p>
            <p>
              I hope you enjoy this blog and please feel free to pass it on to a
              friend if you see something worth sharing.
            </p>
            <p>
              <b>
                <i>- Grant</i>
              </b>
            </p>
            <ShortLine />
            <p>Our site's under construction.</p>
            <p>
              Check back soon or subscribe below to be updated when we're ready.
            </p>
            <p></p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const HeroImage = styled.div`
  position: relative;
`
const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
  padding-bottom: ;
`
const ColorSection = styled.div`
  background-image: linear-gradient(160deg, #d25337 0%, #181313 95%);
  color: #fff;
  padding-top: 5px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  p {
    color: #fff !important;
  }
  a {
    color: #5b9af5;
    &:hover {
      color: #34578b !important;
    }
  }
`
const PurpleSection = styled.div`
  background-image: linear-gradient(316deg, ${colors.meatChar} 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
const SubHeading = styled.h2`
  margin-top: 1em;
`
